import {
  Component, Vue,
} from 'vue-property-decorator';
import PageLayout from '@/layout/Page.vue';
import {
  formatPhone,
} from '@/utils';
import taxi from '@/assets/taxi.json';
import type {
  BreadcrumbInterface,
} from '@/layout/types';
import type {
  TaxiInterface,
} from '@/types';

@Component({
  components: {
    PageLayout,
  },
  metaInfo() {
    return this.$route.meta?.metaInfo;
  },
})
export default class Taxi extends Vue {
  public get breadcrumbs(): BreadcrumbInterface[] {
    return [
      {
        title: this.$route.meta?.breadCrumb,
        active: true,
      },
    ];
  }

  public get taxi(): TaxiInterface[] {
    return taxi;
  }

  public getPhones(taxiId: TaxiInterface['id']): {raw: string, formatted: string}[] {
    return (this.taxi.find(item => item.id === taxiId) as TaxiInterface).phones
      .map(phone => ({
        raw: phone,
        formatted: formatPhone(phone),
      }));
  }
}
